<template>
  <div class="my-4">
    <div class="bg-white shadow rounded flex flex-col flex-shrink w-full">
      <div class="p-4 m-4 h-42 flex flex-row justify-start items-start w-auto border max-w-md" :style="{backgroundColor: colors[activeVariant]}">
        <img src="@/assets/placeholders/example.png" class="h-32 w-32" />
        <div class="flex flex-col ml-4">
          <div class="h-1 w-64 block mt-3" :style="{backgroundColor: textColor }" />
          <div class="h-1 w-48 block mt-3" :style="{backgroundColor: textColor }" />
          <div class="h-1 w-48 block mt-3" :style="{backgroundColor: textColor }" />

          <div
            class="w-24 rounded h-8 p-2 text-white text-center mt-4 flex items-center justify-center"
            :style="{backgroundColor: colors.primary }"
          >
            <span class="text-sm">Lees meer</span>
          </div>
        </div>
      </div>
      <div class="flex flex-row">
        <RadioField class="ml-4" name="variant" value="default" v-model="activeVariant">Witte variant</RadioField>
        <RadioField class="ml-4" name="variant" value="light" v-model="activeVariant">Lichte variant</RadioField>
        <RadioField class="ml-4" name="variant" value="dark" v-model="activeVariant">Donkere variant</RadioField>
      </div>
      <div class="flex flex-col p-4">
        <div class="w-full">kleurenpallette</div>
        <ColorCollection />
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref, computed } from '@vue/composition-api';
import ColorCollection from '@/modules/core/views/components/ColorCollection';
import { colors, activePicker, setColors } from '@/compositions/useColorPicker';
import RadioField from '@/components/field/Radio';

export default {
  name: 'VariantConfigurator',

  components: {
    RadioField,
    ColorCollection,
  },

  props: {
    value: {
      default: undefined,
    },
  },

  setup(props, { emit }) {

    const activeVariant = ref('default');

    const textColor = computed(() => {
      if (activeVariant.value === 'default' || activeVariant.value === 'light') {
        return '#000000';
      }

      return '#ffffff';
    });

    if (props.value !== undefined && Object.keys(props.value).length > 0) {
      setColors(props.value);
    }

    watch(() => activePicker.value, newVal => {
      if (newVal === 'primary' || newVal === 'secondary') return;

      activeVariant.value = newVal;
    });

    watch(() => colors, () => {
      emit('input', colors);
    }, { deep: true });

    return {
      activeVariant,
      activePicker,
      textColor,
      colors,
    };
  },
};
</script>
