<template>
<div>
  <div class="py-2 min-h-32">
    <div class="grid grid-cols-5 gap-4">

      <button
        type="button"
        @click.prevent="activePicker = 'primary'"
        class="flex flex-col rounded bg-white border min-w-24 overflow-hidden col-span-1"
      >
        <div class="w-full h-32" :style="{ backgroundColor: colors.primary}" />
        <p class="p-2 text-sm text-gray-500 border-t w-full">Primair</p>
      </button>

      <button
        type="button"
        @click.prevent="activePicker = 'secondary'"
        class="flex flex-col rounded bg-white border min-w-24 overflow-hidden col-span-1"
      >
        <div class="w-full h-32" :style="{ backgroundColor: colors.secondary}" />
        <p class="p-2 text-sm text-gray-500 border-t w-full">Secundair</p>
      </button>

      <button
        type="button"
        @click.prevent="activePicker = 'default'"
        class="flex flex-col rounded bg-white border min-w-24 overflow-hidden col-span-1"
      >
        <div class="w-full h-32" :style="{ backgroundColor: colors.default}" />
        <p class="p-2 text-sm text-gray-500 border-t w-full">Wit</p>
      </button>

      <button
        type="button"
        @click.prevent="activePicker = 'light'"
        class="flex flex-col rounded bg-white border min-w-24 overflow-hidden col-span-1"
      >
        <div class="w-full h-32" :style="{ backgroundColor: colors.light}" />
        <p class="p-2 text-sm text-gray-500 border-t w-full">Licht</p>
      </button>

      <button
        type="button"
        @click.prevent="activePicker = 'dark'"
        class="flex flex-col rounded bg-white border min-w-24 overflow-hidden col-span-1"
      >
        <div class="w-full h-32" :style="{ backgroundColor: colors.dark}" />
        <p class="p-2 text-sm text-gray-500 border-t w-full">Donker</p>
      </button>
    </div>
  </div>

  <Swatches
    v-if="activePicker"
    fallback-input-type="color"
    v-model="colors[activePicker]"
    class="swatches bg-transparent w-full pt-4"
    show-fallback
    inline
  />
</div>
</template>


<script>
import { ref } from '@vue/composition-api';
import Swatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';

import { colors, activePicker } from '@/compositions/useColorPicker';

export default {
  name: 'ColorCollection',

  props: {
    type: {
      type: String,
      default: 'base',
    },
  },

  components: {
    Swatches,
  },

  setup() {
    return {
      activePicker,
      colors: ref(colors), // ref required to make colors reactive.
    };
  },
};
</script>
