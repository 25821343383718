import { reactive, ref } from '@vue/composition-api';

const activePicker = ref(null);

let colors = reactive({
  primary: '',
  secondary: '',
  default: '#ffffff',
  light: '#fefefe',
  dark: '#000000',
});

const setColors = (c) => {
  colors = c;
};

export { colors, activePicker, setColors };
